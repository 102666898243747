<template>
  <v-card class='elevation-0'>
    <v-card-title>
      <div class='headline font-weight-medium'>
        Patient Details:
      </div>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-text-field label='Patient Id:' v-model='pid'></v-text-field>
      <v-text-field v-model='mobile' label='Registered Mobile:'></v-text-field>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  computed: {
    pid: {
      get() {
        return this.$store.getters['FormsStore/patient'].Pid;
      },
      set(value) {
        this.$store.commit('FormsStore/setPatientId', value);
      }
    },
    mobile: {
      get() {
        return this.$store.getters['FormsStore/patient'].Mobile;
      },
      set(value) {
        this.$store.commit('FormsStore/setPatientMobile', value);
      }
    }
  }
};
</script>

<style scoped></style>
