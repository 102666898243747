<template>
  <v-container grid-list-xl fluid>
    <v-layout row wrap>
      <v-flex sm12>
        <div
          class='display-1 green--text text--darken-4 font-weight-medium center-text'
        >
          Do you have allergy problems?
        </div>
        <br />
        <div class='title green--text text--darken-4 center-text'>
          Answer the following questions. These questions will help us to
          determine the cause of the allergies you are facing.
        </div>
        <br />
      </v-flex>
      <v-flex sm12>
        <FormPatientComp></FormPatientComp>
      </v-flex>
      <v-flex sm12>
        <AllergySurveyComp ref='allergyComp'></AllergySurveyComp>
        <!--<AllergyQuestsComp></AllergyQuestsComp>-->
      </v-flex>
      <v-flex sm12>
        <v-btn color='primary' @click='saveData'>
          <v-icon class='iconText'>fa fa-save</v-icon>
          Save
        </v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import WebH from '../../api/web';
import FormPatientComp from '../../components/patients/FormPatient';
import AllergySurveyComp from '../../components/forms/PatientHistory';
export default {
  components: {
    FormPatientComp,
    AllergySurveyComp
  },
  computed: {
    patient: {
      get() {
        return this.$store.getters['FormsStore/patient'];
      }
    }
  },
  methods: {
    async notify(message) {
      await this.$notifyDlg.show(message, 'Error', 'error');
    },
    async checkForPatientDetails() {
      return this.patient.Pid !== '' && this.patient.Mobile !== '';
    },
    async saveData() {
      const patientCheck = await this.checkForPatientDetails();
      if (!patientCheck) {
        await this.notify('Fill correct patient details');
        return;
      }
      const vModel = {
        Treatments: this.$refs.allergyComp.getTreatments(),
        Family: this.$refs.allergyComp.getFamily(),
        Habits: this.$refs.allergyComp.getHabits(),
        Others: this.$refs.allergyComp.getOthers(),
        Children: this.$refs.allergyComp.getChildren(),
        PatientId: this.patient.Pid,
        Mobile: this.patient.Mobile
      };
      this.submit(vModel);
    },
    async submit(data) {
      const loader = this.$loading.show();
      const res = await WebH.Post('/PatientHistories', data);
      loader.hide();
      if (res.Success) {
        await this.$notifyDlg.show(
          'Answers submitted. Thank you',
          'Info',
          'info'
        );
        this.$refs.allergyComp.clear();
      } else {
        await this.notify(res.Data);
      }
    }
  }
};
</script>

<style scoped></style>
